import { ApiMapData } from "../../../src/types/apimap";

export const NOT_LOGGED_IN_MAP_ASSET = '/maps/world/basic.svg';
export const NOT_LOGGED_IN_MAP_DATA: ApiMapData = {
    "backgroundColor": "#40c0ff",
    "countries": [
        {
            "id": 1,
            "name": "Alaska",
            "x": 58,
            "y": 80,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "alaska"
        },
        {
            "id": 2,
            "name": "Northwest Territory",
            "x": 125,
            "y": 84,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "northwest_territory"
        },
        {
            "id": 3,
            "name": "Greenland",
            "x": 262,
            "y": 56,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "greenland"
        },
        {
            "id": 4,
            "name": "Alberta",
            "x": 117,
            "y": 121,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "alberta"
        },
        {
            "id": 5,
            "name": "Ontario",
            "x": 168,
            "y": 130,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "ontario"
        },
        {
            "id": 6,
            "name": "Quebec",
            "x": 220,
            "y": 131,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "quebec"
        },
        {
            "id": 7,
            "name": "Western States",
            "x": 124,
            "y": 180,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "western_united_states"
        },
        {
            "id": 8,
            "name": "Eastern States",
            "x": 180,
            "y": 187,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "eastern_united_states"
        },
        {
            "id": 9,
            "name": "Central America",
            "x": 123,
            "y": 230,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "central_america"
        },
        {
            "id": 10,
            "name": "Venezuela",
            "x": 180,
            "y": 280,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "venezuela"
        },
        {
            "id": 11,
            "name": "Peru",
            "x": 186,
            "y": 345,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "peru"
        },
        {
            "id": 12,
            "name": "Brazil",
            "x": 240,
            "y": 343,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "brazil"
        },
        {
            "id": 13,
            "name": "Argentina",
            "x": 195,
            "y": 410,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "argentina"
        },
        {
            "id": 14,
            "name": "Iceland",
            "x": 322,
            "y": 105,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "iceland"
        },
        {
            "id": 15,
            "name": "Great Britain",
            "x": 317,
            "y": 170,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "great_britain"
        },
        {
            "id": 16,
            "name": "Western Europe",
            "x": 325,
            "y": 223,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "western_europe"
        },
        {
            "id": 17,
            "name": "Scandinavia",
            "x": 370,
            "y": 115,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "scandinavia"
        },
        {
            "id": 18,
            "name": "Northern Europe",
            "x": 375,
            "y": 168,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "northern_europe"
        },
        {
            "id": 19,
            "name": "Southern Europe",
            "x": 390,
            "y": 218,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "southern_europe"
        },
        {
            "id": 20,
            "name": "Ukraine",
            "x": 446,
            "y": 149,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "ukraine"
        },
        {
            "id": 21,
            "name": "North Africa",
            "x": 357,
            "y": 309,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "north_africa"
        },
        {
            "id": 22,
            "name": "Congo",
            "x": 404,
            "y": 373,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "congo"
        },
        {
            "id": 23,
            "name": "Egypt",
            "x": 406,
            "y": 289,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "egypt"
        },
        {
            "id": 24,
            "name": "East Africa",
            "x": 445,
            "y": 345,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "east_africa"
        },
        {
            "id": 25,
            "name": "South Africa",
            "x": 416,
            "y": 434,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "south_africa"
        },
        {
            "id": 26,
            "name": "Madagascar",
            "x": 476,
            "y": 440,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "madagascar"
        },
        {
            "id": 27,
            "name": "Middle East",
            "x": 458,
            "y": 275,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "middle_east"
        },
        {
            "id": 28,
            "name": "Ural",
            "x": 515,
            "y": 125,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "ural"
        },
        {
            "id": 29,
            "name": "Afghanistan",
            "x": 501,
            "y": 189,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "afghanistan"
        },
        {
            "id": 30,
            "name": "India",
            "x": 544,
            "y": 274,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "india"
        },
        {
            "id": 31,
            "name": "Siberia",
            "x": 555,
            "y": 90,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "siberia"
        },
        {
            "id": 32,
            "name": "China",
            "x": 592,
            "y": 226,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "china"
        },
        {
            "id": 33,
            "name": "Yakutsk",
            "x": 609,
            "y": 71,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "yakutsk"
        },
        {
            "id": 34,
            "name": "Irkutsk",
            "x": 602,
            "y": 126,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "irkutsk"
        },
        {
            "id": 35,
            "name": "Mongolia",
            "x": 612,
            "y": 180,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "mongolia"
        },
        {
            "id": 36,
            "name": "Kamchatka",
            "x": 664,
            "y": 75,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "kamchatka"
        },
        {
            "id": 37,
            "name": "Japan",
            "x": 684,
            "y": 180,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "japan"
        },
        {
            "id": 38,
            "name": "Siam",
            "x": 608,
            "y": 289,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "siam"
        },
        {
            "id": 39,
            "name": "Indonesia",
            "x": 615,
            "y": 365,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "indonesia"
        },
        {
            "id": 40,
            "name": "Western Australia",
            "x": 645,
            "y": 435,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "western_australia"
        },
        {
            "id": 41,
            "name": "New Guinea",
            "x": 676,
            "y": 347,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "new_guinea"
        },
        {
            "id": 42,
            "name": "Eastern Australia",
            "x": 710,
            "y": 435,
            "startNeutral": false,
            "attackBonus": 0,
            "defendBonus": 0,
            "maxUnits": 0,
            "domId": "eastern_australia"
        }
    ],
    "continents": [
        {
            "countries": [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9
            ],
            "id": 1,
            "name": "North America",
            "bonus": 5
        },
        {
            "countries": [
                10,
                11,
                12,
                13
            ],
            "id": 2,
            "name": "South America",
            "bonus": 2
        },
        {
            "countries": [
                14,
                17,
                15,
                18,
                20,
                16,
                19
            ],
            "id": 3,
            "name": "Europe",
            "bonus": 5
        },
        {
            "countries": [
                21,
                23,
                24,
                22,
                25,
                26
            ],
            "id": 4,
            "name": "Africa",
            "bonus": 3
        },
        {
            "countries": [
                37,
                36,
                33,
                34,
                35,
                31,
                32,
                28,
                29,
                30,
                27,
                38
            ],
            "id": 5,
            "name": "Asia",
            "bonus": 7
        },
        {
            "countries": [
                42,
                40,
                39,
                41
            ],
            "id": 6,
            "name": "Australia",
            "bonus": 2
        }
    ],
    "borders": [
        {
            "from": 1,
            "to": 2,
            "type": 0
        },
        {
            "from": 2,
            "to": 3,
            "type": 0
        },
        {
            "from": 1,
            "to": 4,
            "type": 0
        },
        {
            "from": 2,
            "to": 5,
            "type": 0
        },
        {
            "from": 3,
            "to": 6,
            "type": 0
        },
        {
            "from": 4,
            "to": 5,
            "type": 0
        },
        {
            "from": 5,
            "to": 6,
            "type": 0
        },
        {
            "from": 4,
            "to": 7,
            "type": 0
        },
        {
            "from": 5,
            "to": 8,
            "type": 0
        },
        {
            "from": 7,
            "to": 8,
            "type": 0
        },
        {
            "from": 7,
            "to": 9,
            "type": 0
        },
        {
            "from": 8,
            "to": 9,
            "type": 0
        },
        {
            "from": 2,
            "to": 4,
            "type": 0
        },
        {
            "from": 3,
            "to": 5,
            "type": 0
        },
        {
            "from": 5,
            "to": 7,
            "type": 0
        },
        {
            "from": 6,
            "to": 8,
            "type": 0
        },
        {
            "from": 9,
            "to": 10,
            "type": 0
        },
        {
            "from": 10,
            "to": 11,
            "type": 0
        },
        {
            "from": 11,
            "to": 12,
            "type": 0
        },
        {
            "from": 10,
            "to": 12,
            "type": 0
        },
        {
            "from": 11,
            "to": 13,
            "type": 0
        },
        {
            "from": 12,
            "to": 13,
            "type": 0
        },
        {
            "from": 12,
            "to": 21,
            "type": 0
        },
        {
            "from": 3,
            "to": 14,
            "type": 0
        },
        {
            "from": 14,
            "to": 17,
            "type": 0
        },
        {
            "from": 14,
            "to": 15,
            "type": 0
        },
        {
            "from": 15,
            "to": 17,
            "type": 0
        },
        {
            "from": 15,
            "to": 18,
            "type": 0
        },
        {
            "from": 17,
            "to": 18,
            "type": 0
        },
        {
            "from": 17,
            "to": 20,
            "type": 0
        },
        {
            "from": 20,
            "to": 18,
            "type": 0
        },
        {
            "from": 18,
            "to": 19,
            "type": 0
        },
        {
            "from": 20,
            "to": 19,
            "type": 0
        },
        {
            "from": 15,
            "to": 16,
            "type": 0
        },
        {
            "from": 16,
            "to": 18,
            "type": 0
        },
        {
            "from": 16,
            "to": 19,
            "type": 0
        },
        {
            "from": 16,
            "to": 21,
            "type": 0
        },
        {
            "from": 21,
            "to": 23,
            "type": 0
        },
        {
            "from": 21,
            "to": 24,
            "type": 0
        },
        {
            "from": 21,
            "to": 22,
            "type": 0
        },
        {
            "from": 19,
            "to": 23,
            "type": 0
        },
        {
            "from": 23,
            "to": 24,
            "type": 0
        },
        {
            "from": 24,
            "to": 22,
            "type": 0
        },
        {
            "from": 24,
            "to": 25,
            "type": 0
        },
        {
            "from": 24,
            "to": 26,
            "type": 0
        },
        {
            "from": 22,
            "to": 25,
            "type": 0
        },
        {
            "from": 25,
            "to": 26,
            "type": 0
        },
        {
            "from": 20,
            "to": 27,
            "type": 0
        },
        {
            "from": 19,
            "to": 27,
            "type": 0
        },
        {
            "from": 23,
            "to": 27,
            "type": 0
        },
        {
            "from": 24,
            "to": 27,
            "type": 0
        },
        {
            "from": 20,
            "to": 28,
            "type": 0
        },
        {
            "from": 20,
            "to": 29,
            "type": 0
        },
        {
            "from": 27,
            "to": 29,
            "type": 0
        },
        {
            "from": 27,
            "to": 30,
            "type": 0
        },
        {
            "from": 28,
            "to": 31,
            "type": 0
        },
        {
            "from": 28,
            "to": 32,
            "type": 0
        },
        {
            "from": 28,
            "to": 29,
            "type": 0
        },
        {
            "from": 29,
            "to": 32,
            "type": 0
        },
        {
            "from": 29,
            "to": 30,
            "type": 0
        },
        {
            "from": 30,
            "to": 32,
            "type": 0
        },
        {
            "from": 31,
            "to": 33,
            "type": 0
        },
        {
            "from": 31,
            "to": 34,
            "type": 0
        },
        {
            "from": 31,
            "to": 35,
            "type": 0
        },
        {
            "from": 31,
            "to": 32,
            "type": 0
        },
        {
            "from": 33,
            "to": 36,
            "type": 0
        },
        {
            "from": 33,
            "to": 34,
            "type": 0
        },
        {
            "from": 34,
            "to": 36,
            "type": 0
        },
        {
            "from": 34,
            "to": 35,
            "type": 0
        },
        {
            "from": 35,
            "to": 36,
            "type": 0
        },
        {
            "from": 36,
            "to": 37,
            "type": 0
        },
        {
            "from": 35,
            "to": 37,
            "type": 0
        },
        {
            "from": 35,
            "to": 32,
            "type": 0
        },
        {
            "from": 32,
            "to": 38,
            "type": 0
        },
        {
            "from": 38,
            "to": 39,
            "type": 0
        },
        {
            "from": 39,
            "to": 41,
            "type": 0
        },
        {
            "from": 39,
            "to": 40,
            "type": 0
        },
        {
            "from": 41,
            "to": 42,
            "type": 0
        },
        {
            "from": 41,
            "to": 40,
            "type": 0
        },
        {
            "from": 40,
            "to": 42,
            "type": 0
        },
        {
            "from": 36,
            "to": 1,
            "type": 0
        },
        {
            "from": 19,
            "to": 21,
            "type": 0
        },
        {
            "from": 38,
            "to": 30,
            "type": 0
        }
    ],
    "cards": [
        {
            "id": 1,
            "name": "Ammunition",
            "count": 18,
            "isWild": false
        },
        {
            "id": 2,
            "name": "Rations",
            "count": 18,
            "isWild": false
        },
        {
            "id": 3,
            "name": "Supplies",
            "count": 18,
            "isWild": false
        },
        {
            "id": 4,
            "name": "Wild",
            "count": 2,
            "isWild": true
        }
    ],
    "rules": null,
    "width": 750,
    "height": 519,
    "version": "1.0",
    "textSize": 20,
    "mapColor": null
};
